import React from 'react'
import {Grid, Link, Button } from '@material-ui/core'
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'; 
import {makeStyles, withStyles } from '@material-ui/core/styles'

const ExpansionPanel = withStyles({
  root: {
    border: '1px solid #333',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiExpansionPanel);


const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: '#333',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    backgroundColor:"#333"
  },
}))(MuiExpansionPanelDetails);



const useStyles = makeStyles({
    text: {
        color:"#ffff",
        letterSpacing:'0.08em',
        float:"left",
        fontSize:'20px',

    },
    description:{
      color:"#ffff",
        letterSpacing:'0.08em',
        float:"left",
        fontSize:'20px',
        paddingTop:'30%'

    },
    header:{
      color:'#64FFDA',
      letterSpacing:'0.08em',
    }
})
const Restaskest = () => {
  const classes = useStyles();

  const [expanded, setExpanded] = React.useState('panel1');

  const handleChange = panel => (event, newExpanded) =>{
         if(newExpanded) {
           setExpanded(panel)
         }
         else {
           setExpanded(false)
         }
  }

  return(
      <> 
        <Grid container> 
          <Grid item xs={4}>
            <p className={classes.description}> These are descriptions about Restaskest application, all of these is just summary. You can found detail documents on github .</p>
          </Grid>
          <Grid item xs={8} style={{paddingRight:"5%"}}>
            <ExpansionPanel square expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
              <ExpansionPanelSummary aria-controls = "panel1d-content  " id = "panel1d-header">
                <h3 className={classes.header}> Project Vision</h3>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                  <p className={classes.text} >
                    Our goal is to create a innovated and simplified application that helps small to medium size restaurant in Toronto to simplify and combine all functionalities of management of restaurant's resources. By reducing the time-consuming of the tasks of management, 
                    the owner/manager can focus on developing and expanding their businesses.  
                    
                  </p>
              </ExpansionPanelDetails>
              </ExpansionPanel>
              <ExpansionPanel square expanded = {expanded === 'panel2'} onChange={handleChange('panel2')}>
              <ExpansionPanelSummary aria-controls = "panel2d-content  " id = "panel2d-header">
                <h3 className={classes.header}> Project Requirements</h3>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                  <p className={classes.text}>
                    Because Restaskest have a web-based application, Android and IOS version, developers have to knowledged in MEAN stacks and Native Script. We have chosen the NoSQL database for our system which is MongoDB. 
                    As I have learnt Javascript from some previous semesters, this is a challenge as well as an opportunity for me to practice and sharpen my skills.
                  </p>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel square expanded = {expanded === 'panel3'} onChange={handleChange('panel3')}>
              <ExpansionPanelSummary aria-controls = "panel3d-content  " id = "panel3d-header">
                <h3 className={classes.header}> Project Plans</h3>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                  <p className={classes.text}>
                  As we have already finished the analysis and design phases in semeser 5. Currently, we are in last steps of development phase before getting to the test and deployment. 
                  Our plan is to introduce final product in the end of March.
                  </p>
              </ExpansionPanelDetails>
              </ExpansionPanel>

              <ExpansionPanel square expanded = {expanded === 'panel4'} onChange={handleChange('panel4')}>
              <ExpansionPanelSummary aria-controls = "panel4d-content  " id = "panel4d-header">
                <h3 className={classes.header}> Wireframe/ Mockups </h3>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                  <p className={classes.text}>
                  As we have design our visual aspects of application by using figma. You can check it out by click on the link.
                  </p>
                
                 
                 <p className={classes.text}> Website version: <span><Link target="_blank" rel="noopener" href=" https://www.figma.com/file/4ziukeKzoys3Tio2YA9W5i/T30_Mockup_WebVersion" style={{textDecoration:'none'}} > <Button variant="outlined" color="primary"> Link</Button> </Link> </span> </p>
                 <p className={classes.text}> Mobile version: <span><Link target="_blank" rel="noopener" href=" https://www.figma.com/file/Hq5VkkVJbeT4W7sXJJO21t/T30_MockUp_MobileVersion" style={{textDecoration:'none'}}> <Button variant="outlined" color="primary"> Link</Button> </Link> </span> </p> 
               
              </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel square expanded = {expanded === 'panel5'} onChange={handleChange('panel5')}>
              <ExpansionPanelSummary aria-controls = "panel5d-content  " id = "panel5d-header">
                <h3 className={classes.header}> Status report </h3>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                  Business Landing Website : not ready 
                  Web application : not ready
                  Mobile application : not ready
              </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel square expanded = {expanded === 'panel6'} onChange={handleChange('panel6')}>
              <ExpansionPanelSummary aria-controls = "panel6d-content  " id = "panel6d-header">
                <h3 className={classes.header}> System Implementation </h3>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                  Currently in development...
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </Grid>
        </Grid>

      </>
  )
}

export default Restaskest