import React from 'react';
import {Paper,Grid } from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import avatar from '../../assets/images/quang_new.jpg';
const useStyles = makeStyles({
    bio :{
      display:'flex',
      padding:'10% 5%'
    },
    paper:{
      backgroundColor:"#121212",
      justifyContent:'center',
      textAlign:'left',
      
    },
    text:{
      
      color:"#FFFF",
      letterSpacing:"0.08em"
    },
    image:{
      width:'100%',
    
    },
    imgContainer:{
      width:'100%'
    },
    title:{
      color:"#64FFDA",
      letterSpacing:"0.1em",
      fontSize:"60px",
      fontStyle:"bold",
      
    },
    quote :{
      color:"#FFFF",
      letterSpacing:"0.08em",
      fontSize:'18px',
      fontStyle:'italic',
    
    },
    quoteContainer:{
        float:'right'
    }
})

const Aboutme = () =>{
    const classes = useStyles();
    return(
      <React.Fragment> 
      <Grid container >
        <Grid item xs={8}> 
        <div className={classes.bio}> 
      <Paper elevation={3} className={classes.paper} >
          <h2 className={classes.title}>
          About me <span role='img' aria-label="student">👨‍🎓</span>
          </h2>
        <p className={classes.text}>
          My full name is Cong Nhat Quang Pham who currently in the last semester in Computer Programming Analyst program at George Brown College.
          
        </p>
        <p className={classes.text}> 
          I'm a self-learning, dedicated and motivated student, who interested in Web development. A fan of football, outdoor activities, gym, video game, and action or fantasy movies.
        </p>
        <p className={classes.text}> 
        For the school's years, I wasn't the best in my school, I've always put all effort to my works and studies. 
          I've spent a lot of time to learnt and practiced in many projects and school's assignments. 
        This website demonstrates about myself and my experiences at school as well as my learning journey.
          There are a lot of thing that I have not knowledged, so feel free to share any ides or good sources with me.
        </p>
        <div className={classes.quoteContainer}>  
        <p className={classes.quote}> "It's a marathon, not a sprint " </p>
        <p className={classes.quote}> by Drake </p> 
        </div>
       
      </Paper>
      </div>
        </Grid>

        <Grid item xs={4}>
            <div className={classes.imgContainer}>
          <img className={classes.image} alt="Avatar"  src={avatar} />
          </div>
        </Grid>

      </Grid>
      
      </React.Fragment>
    )
}

export default Aboutme;

