import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import {Card, CardActionArea, CardContent, CardMedia, Button, Typography,CardActions} from '@material-ui/core'
import {Link} from 'react-router-dom'
import ResImage from '../../../assets/images/restaskest.png'
import GameLobby from '../../../assets/images/game-lobby.png'
import PriceAnalysis from '../../../assets/images/price-analysis.png'
import ChatApp from '../../../assets/images/chat-app.png'


const useStyles = makeStyles({

  root: {
    backgroundColor:"#333",
    color: "#FFFF",
    fontWeight:"bolder",
    width:'100%',
    height:'30%',
    paddingBottom:'2%'
  },
  content:{
    color: "#FFFF",
    fontSize:"12px",
    fontFamily:"Lato"
  },
  image: {
 
    height:"30%",
    margin:'0',
    padding:'0',
    width:"30%",
    zIndex:'-1',
    float:'center',
    textAlign:'center'
  }
}
)

const ProjectCard = (props) => {
  const classes = useStyles()
 
  const render = (image) =>{
      switch (image)
      {
          case 'ResImage':
               return ResImage;
          case 'GameLobby':
             return GameLobby;
          case 'PriceAnalysis':
            return PriceAnalysis;
          case 'ChatApp' :
              return ChatApp;
          default:
            return ResImage;
      }
         
  }
 
    return (
     <> 
       {props.arrayWorks.map((item , index) => { 
          return (
            <div>
            <Card className={classes.root} > 
          <CardActionArea>
            <CardMedia 
              className={classes.image}
              component="img"
              alt="Project Logo"
              image={render(item.image)}
              title="Project"/>
            <CardContent > 
              <Typography gutterBottom variant="h5" component="h2" >
                  {item.name}
              </Typography>
              <Typography variant="body2" color="textSecondary"  component="p" className={classes.content}>
                  {item.description}
              </Typography>
              </CardContent>
          </CardActionArea>
          <CardActions> 
          <a target='_blank' rel="noopener noreferrer" href={item.github}
          style={{'textDecoration' : 'none'}}
            >
            <Button size="small" color="primary">
                Visit it on github
            </Button>
            </a>
            <Link to='/Restaskest'
              style={{'textDecoration' : 'none'}}
             >
            <Button   
            disabled={!item.link}
            size="small" color="primary">
                View more
            </Button>
            </Link>
           
          </CardActions>
        </Card>
        </div>
        )
       })}
        
      </>
    )
}

export default ProjectCard;