import React from 'react';
import {List, ListItem, IconButton} from '@material-ui/core';
import {makeStyles} from '@material-ui/core';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import LaptopIcon from '@material-ui/icons/Laptop';
import SchoolIcon from '@material-ui/icons/School';
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import CodeIcon from '@material-ui/icons/Code';
import GitHubIcon from '@material-ui/icons/GitHub';
import {Link} from 'react-router-dom';
import './Navbar.css';
import logo from '../../assets/images/logo.png';
const useStyle = makeStyles(theme=>({
    
    listItem:{
        fontSize:'12',
        float:'center',
        textAlign:'center',
        
    },
    link: {
        textDecoration:'none',
    },
    icon : {
      color:'#64FFDA',
      width:'20%',
      '&:hover': {
        color:"#A7FFEB" ,
        transition:'all 0.2s ease-in-out',  
      },
      textAlign:'center'
    },
    

    
    
}))


const Navbar = () =>{
  const classes = useStyle();
  
  return (
    
    <div className="navbar-container">
    <div className="logo-container"> 
    <List component="logo" arira-label ="logo-container">
        <ListItem >
            <Link  to="/" style={{color: "#64FFDA"}}> <img width="60" height="67" alt="logo" src={logo}/> </Link>
        </ListItem>
    </List>
    </div>
    
    <div className = "menu-container"> 
    <List clasName="list-containter" component="nav" aria-label="main menu-navigation">
      <ListItem >
        <Link to="/aboutme" > <IconButton className={classes.icon} > <PermIdentityIcon  />  </IconButton> </Link>
      </ListItem>
      <ListItem>
       
        <Link to="/myworks"> <IconButton className={classes.icon}>  <LaptopIcon   /> </IconButton> </Link>
      </ListItem>
      <ListItem>
      
      <Link to="/skills"><IconButton className={classes.icon}> <CodeIcon   /> </IconButton>   </Link>
      </ListItem>
      <ListItem>
      
        <Link to="/academics"> <IconButton className={classes.icon}>  <SchoolIcon  /> </IconButton>  </Link>
      </ListItem>
    </List>
    </div>
 
    <div clasName="social-lists-container"> 
    <List component="social" arira-label ="social-container">
        <ListItem >
            <a href="https://www.facebook.com/nhat.quang.503" target="_blank" rel="noopener noreferrer"> <FacebookIcon  fontSize="small" style={{color:"#64FFDA", fontSize:15}}/>  </a>
        </ListItem>
        <ListItem >
            <a href="http://linkedin.com/in/pham-quang-723430141" target="_blank" rel="noopener noreferrer"> <LinkedInIcon  fontSize="small" style={{color: "#64FFDA", fontSize:15}} />  </a>
        </ListItem>
        <ListItem >
            <a href="https://github.com/quangpham919" target="_blank" rel="noopener noreferrer"> <GitHubIcon   style={{color: "#64FFDA", fontSize:15}}/>  </a>
        </ListItem>
        
    </List>
    </div>
    </div>
   
  )
} 

export default Navbar;
