import React from 'react'
import './Home.css'
import {Grid,Button,withStyles,Link} from '@material-ui/core'

import Typical from 'react-typical';
import {cyan} from '@material-ui/core/colors'

const ColorButton = withStyles(theme => ({
  root: {
    color: theme.palette.getContrastText(cyan[200]),
    backgroundColor: cyan[200],
    '&:hover': {
      backgroundColor: cyan[700],
    },
  },
}))(Button);
const Home = () =>{
   
    return(
       <> 
      
      <Grid container>
        <Grid item xs={6}>
        
          <h1 className="myself first">  Quang </h1>
          <h1 className="myself last"> Pham.    </h1>
        </Grid>
        <Grid item xs={6}>
        <div className="paragraph"> 
          <p id="text"> Hello World!, </p>
          <p id="text"> I'm a motivated, self-learning
          <Typical 
          loop={Infinity}
          wrapper="b"
          steps={[
            ' front end developer 💻 ',1000,
            ' back end developer 🖥 ',1000,
            ' web designer 💡',1000,
            ' student 👨‍🎓 ',1000,
          ]}
          >    
           
          </Typical>
          </p>
         
        </div>
        <div className="button-holder">
        <Link target="_blank" rel="noopener" href="https://drive.google.com/open?id=1vRXHg-UGjAUgXEZjckrHWShbMmHOV0ma" className="link-btn" style={{textDecoration:"none"}} > <ColorButton  variant="outlined"  color="primary"  >View my resume   </ColorButton>  </Link>
        </div>
        </Grid>
        <Grid item xs={6}>
        </Grid>
        <Grid item xs={6}>
        </Grid>
      </Grid>
     
      </> 
    )
}

export default Home;