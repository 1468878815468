import React from 'react'
import Rating from '@material-ui/lab/Rating';
import FavoriteIcon from '@material-ui/icons/Favorite'
import {Box,makeStyles} from '@material-ui/core'

const useStyles = makeStyles({
    rating:{
      color: '#ff3d47',
      fontSize:'25px',
      wordSpacing:'0.5em'
      
    },
    lblName:{
      color: '#ffff'
    }
    
})
const RatingSkill = (props) =>{
  const classes = useStyles();
  return(
    props.arraySkills.map((item,index)=>{
        return(
            
            <Box component="fieldset" md={3} borderColor="transparent" className="root">
            <p className={classes.lblName}>{item.name}</p>
            <Rating 
            className={classes.rating}
            name = "readOnly"
            value= {item.value}
            size="large"
            icon = {<FavoriteIcon fontSize="inherit"/>
            }
            readOnly/>
            </Box>
        )
     })   

    )
}
export default RatingSkill;