import React from 'react'
import TranscriptImg from '../../assets/images/transcript.png'
const Transcript = () =>{
  return (
      <> 
      <div> 
        <img alt="transcript" src={TranscriptImg} width="70%" height= "80%" float="center"  />
        </div>
      </>
  )
}

export default Transcript