import React from 'react'
import {Grid} from '@material-ui/core';
import './App.css'
import Navbar from './components/navbar/Navbar'
import Aboutme from './components/About Me/Aboutme'
import Home from './components/Home/Home'
import Skills from './components/Skills/Skills'
import Works from './components/MyWorks/works'
import Restaskest from './components/Restaskest/Restaskest'
import Academic from './components/Academic/Academic'
import Transcript from './components/Transcript/Transcript'
import {Route, BrowserRouter as Router,Switch} from 'react-router-dom'
function App() {
    
  

  return (
    <>
    <Router>

    <Grid container  className="main-container" >
      <Grid item xs= {1}>
        <Navbar />
      </Grid>
      <Grid item xs= {11} className="content-container">
              <Route render= {({location}) => (
               
                    
                     <Switch> 
                     <Route exact path="/" >  <Home/>   </Route>
                     <Route path="/aboutme">  <Aboutme/>  </Route>
                     <Route path="/skills" ><Skills/>   </Route>
                     <Route path="/myworks">  <Works/>  </Route>
                     <Route path="/restaskest"> <Restaskest/> </Route>
                     <Route path="/academics" > <Academic /> </Route>
                     <Route path="/transcript"> <Transcript /></Route>
                      <Route path="*"> <Home/></Route>
                    </Switch>
                     
                  
              )  
              }
              
             />
      </Grid>
    </Grid>

    </Router>
    </>
  );
}

export default App;
