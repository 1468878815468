import React from 'react'
import ProjectCard from './Work Container/ProjectCard'
import {makeStyles} from '@material-ui/core/styles'
import {Grid} from '@material-ui/core'

const useStyles = makeStyles({
    cardList:{
        margin:'0 20%',
        width:'80%',
        height:'8 0%'
    },
    header:{
      
      color:'#64FFDA',
      letterSpacing: '0.1em',
      fontSize: '30px',
      fontWeight: 'bolder'
    },
    list:{
      padding:'0',
      margin:'0'
    }
})

const Works = () =>{
  const classes = useStyles()
  const arrayofWork1  =[
    {name:'Restaskest',
     description: ` Restaskest is a cross-platform restaurant management system, which helps the manager to reduce the tasks of management restaurant's resouces, and focus on improving the processes of the business.
     Its website version is built based on MEAN stacks, and its Android and IOS version is developed by using Native Scripts. Restaskest is my first professionals project that I have done with my friends. `,
     link:true,
     github:'https://github.com/tylrtnguyen/CapstoneProject'
    },
    {
      name:'Game Lobby Application',
      description:` This is a project that I have done for my full-stack course, which is a reproduction of every game lobbies. 
      My goal for this app is practicing the implementation of Angular, Nodejs And Mongodb in a real-application.`,
      image:'GameLobby',
      link:false,
      github:'https://github.com/quangpham919/Full_Stack_Project'
    }
  ];
  const arrayofWork2 = [
    {
      name:'Ontario House Pricing Plan',
      description:`This is a python project of implementation of data processing by using pandas and sklearn. What we have worked here is using methods and functions which is provided by pandas python's library to extract useful data 
      from a raw dataframe about the price of house and apartment in Ontario. At the end, we've used the extracted information as an input for training a model. That model, which is given a price, will predict is it a house or a aparment`,
      image:'PriceAnalysis',
      link:false,
      github:'https://github.com/quangpham919/ontario-house-price-analysing'
    },

    {
      name:'Chat-application',
      description:` My very first app working with React as a front-end framewok, which is also one of my school's project. This is an simplified real-time chat application, which is built based on MERN stacks. 
        New user just need to pick a nickname then he/she can chat with others in a room. The tool I've used here to handle message is Socket.IO, which is an Nodejs framework. `,
      image:'ChatApp',
      link:false,
      github:'https://github.com/tylrtnguyen/realtime-chat-app'
    }
  ];
  return(
    <>
    <Grid container> 
    <Grid item xs={2}> 
      <p className={classes.header}> My Works  </p>
    </Grid>

      <Grid item xs={5} className={classes.list}>
      <div className={classes.cardList}>
        <ProjectCard arrayWorks={arrayofWork1} />
      </div>
      </Grid> 

      <Grid item xs={5} className={classes.list}>
      <div className={classes.cardList}>
        <ProjectCard arrayWorks={arrayofWork2} />
      </div>
      </Grid> 
     
      
    </Grid>
     

    </>  
  )
}

export default Works; 