import React from 'react';
import {Paper,Grid,makeStyles,Card} from '@material-ui/core';
import RatingSkill from './ratingSkill';
import './Skills.css';
const useStyles = makeStyles({
    Paper:{
        backgroundColor:"#121212",
        justifyContent:'center',
        textAlign:'auto',
        letterSpacing:'0.05em'
        
    },
    ratingChart:{
        backgroundColor:"#121212",
        marginLeft:'15%',

        justifyContent:"center",
        color:'#ffff',

    }
})
const Skills = () =>{
  const classes = useStyles();
  const skillList = [
      {name:"React", value: 5},
      {name:"Angular", value: 5},
      {name:"Nodejs", value: 4},
     
  ]
  const skillList2 = [
    {name:"Python", value: 4},
    {name:"JAVA ", value: 3},
    {name:"C#", value: 3},
  ]
  return(
   <> 
      <h1> My Skills <span role="img" aria-label="rocket">🚀</span> </h1>
    
    <Grid container className="content-container">
    <Grid item xs={4}>
      <Paper className={classes.Paper} > 
      <p id="intro"> These skills of mine has been developed by assignments and side projects when I was learning in college. And this chart is my preference of programming technologies which I have implemented in my product.  </p>
      </Paper>
    </Grid> 
    <Grid item xs={4}>
      <Card className={classes.ratingChart}> 
      <RatingSkill arraySkills={skillList}/>
      </Card>
    </Grid>
    <Grid item xs={4}>
      <Card className={classes.ratingChart}> 
      <RatingSkill arraySkills={skillList2}/>
      </Card>
    </Grid>
    </Grid>
    
   </>
  )
}

export default Skills;