import React from 'react'
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import SchoolIcon from '@material-ui/icons/School'
import './Academic.css'
import {Button} from '@material-ui/core'
import {Link} from 'react-router-dom'
const Academic = () => {

  return(
      <> 
        <h1> Academic path </h1>
        <div id="button"> 
        <Link to='/transcript' style={{textDecoration:'none'}}> <Button variant='outlined' color="primary"> View my transcript</Button> </Link>
        </div> 
        <VerticalTimeline> 
          <VerticalTimelineElement
             className="vertical-timeline-element--school"
             contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
             date="present"
             iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
             icon={<SchoolIcon/>}
          > 
          <h3 className="vertical-timeline-element-title">Seniors at George Brown College</h3>
          
          <h4 className="vertical-timeline-element-subtitle">Computer Programmer Analyst</h4>
          
          <p> Plan to graduate on April 2020</p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
             className="vertical-timeline-element--school"
             contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
             date="2018"
             iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
             icon={<SchoolIcon/>}
          > 
          <h3 className="vertical-timeline-element-title">Freshman at George Brown College </h3>
          <h4 className="vertical-timeline-element-subtitle">Start to study first semester of Computer Programmer Analyst</h4>
          </VerticalTimelineElement>
        </VerticalTimeline>
      </>
  )
} 

export default Academic;